import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TranslatableText } from './language';

export default ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} toggle={toggle} className={'dinHelpModal'} labelledBy="din-modal-header">
    <ModalHeader toggle={toggle} tag="h3" id="din-modal-header">
      <TranslatableText
        dictionary={{
          english: 'DIN information',
          french: 'Informations sur le DIN',
        }}
      />
    </ModalHeader>
    <ModalBody>
      <TranslatableText
        dictionary={{
          english: 'The DIN is the 8 digit number appearing in the top right corner of the box your ENSPRYNG came in.',
          french:
            'Le DIN est le nombre à huit chiffres qui figure dans le coin supérieur droit de la boîte de ENSPRYNG.',
        }}
      />
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>
        <TranslatableText
          dictionary={{
            english: 'Close',
            french: 'Fermer',
          }}
        />
      </Button>
    </ModalFooter>
  </Modal>
);
