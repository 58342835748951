import React from 'react';
import './login-form.scss';

import { TranslatedBlock, TranslatableText } from './language';
import DinHelpModal from './din-help-modal';
import { HcpLoginForm } from './hcp-form-section';
import { PatientLoginForm } from './patient-form-section';
import useToggle from 'src/hooks/use-toggle.js';

export const LoginForm = ({ toggleDinModal, isDinModalOpen, error, mode }) => {
  return (
    <main className="roche-components-login-form">
      <div className="login-content">
        <div className="container container-small container-md-full login-small-container">
          <h1 className="header-normal">
            <TranslatableText
              dictionary={{
                english: 'Welcome to ENSPRYNG.ca',
                french: 'Bienvenue à ENSPRYNG.ca',
              }}
            />
          </h1>
          <div className="row">
            {mode === 'both' && (
              <>
                <PatientLoginForm toggleDinModal={toggleDinModal} />
                <HcpLoginForm />
              </>
            )}

            {mode === 'onlyHcpLoginForm' && <HcpLoginForm fullWidth="true" />}

            {mode === 'onlyPatientLoginForm' && <PatientLoginForm toggleDinModal={toggleDinModal} fullWidth="true" />}

            <TranslatedBlock language="english">
              <div className="login-info">
                This website contains materials intended only for residents of Canada, and may contain product details
                or information not available or valid outside of Canada. We accept no responsibility for users outside
                of Canada accessing portions of this website which may be invalid or non-compliant with the laws,
                regulations or standards of the user’s home country.
              </div>
            </TranslatedBlock>
            <TranslatedBlock language="french">
              <div className="login-info">
                Ce site Web présente de l’information destinée aux résidents du Canada seulement et peut contenir des
                détails ou des renseignements sur des produits qui ne pourraient être autrement accessibles ou qui ne
                sont pas valides dans d’autres pays. Nous n’assumons aucune responsabilité envers les utilisateurs de
                l’extérieur du Canada qui consultent certaines parties de ce site Web, puisque ces dernières peuvent
                être invalides dans leur pays ou non conformes aux lois, aux règlements ou aux normes qui y sont en
                vigueur.
              </div>
            </TranslatedBlock>
          </div>
        </div>
      </div>

      {(mode === 'both' || mode === 'onlyPatientLoginForm') && (
        <>
          <DinHelpModal isOpen={isDinModalOpen} toggle={toggleDinModal} />
        </>
      )}
    </main>
  );
};

export default function LoginFormWrapper({ mode }) {
  const [isDinModalOpen, toggleDinModal] = useToggle(false);
  return <LoginForm mode={mode} isDinModalOpen={isDinModalOpen} toggleDinModal={toggleDinModal} />;
}
