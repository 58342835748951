import React from 'react';
import './login-header.scss';

import { SmallLanguageSwitcher } from './language';
import RocheLogo from '../images/Roche_logo.png';

export default function LoginHeader() {
  return (
    <header className="login-topbar">
      <div className="container">
        <div className="roche-logo-container">
          <SmallLanguageSwitcher />
          <img src={RocheLogo} alt="Roche logo" className={'roche-logo-img'} />
        </div>
      </div>
    </header>
  );
}
