import React from 'react';
import './index.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Footer from 'src/components/footer';
import Helmet from 'react-helmet';
import LoginForm from 'src/components/login-form';
import LoginHeader from 'src/components/login-header';

export default class LoginPage extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('login');
    this.context.setPage('login');
  }

  render() {
    return (
      <>
        <AppStateContext.Consumer>
          {({ highContrast, language }) => (
            <Helmet
              title={
                language === 'french'
                  ? 'Étapes de l’injection de ᴾʳENSPRYNG™'
                  : 'Injecting ᴾʳENSPRYNG™ a Step-by-Step Guide'
              }
              meta={[
                {
                  name: 'description',
                  content:
                    language === 'french'
                      ? 'Ce site offre aux personnes à qui l’on a prescrit ENSPRYNG™ de l’information pour effectuer les injections sous-cutanées.'
                      : 'For people that have been prescribed ENSPRYNG™ this site provides you information on how to perform the subcutaneous injections.',
                },
              ]}
            >
              <html lang={language === 'french' ? 'fr' : 'en'} />
              <script
                type="text/javascript"
                src={'https://cdn.cookielaw.org/consent/' + process.env.GATSBY_OT_SCRIPT_ID + '/OtAutoBlock.js'}
              ></script>
              <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                data-document-language="true"
                type="text/javascript"
                charset="UTF-8"
                data-domain-script={process.env.GATSBY_OT_SCRIPT_ID}
              ></script>
              <script type="text/javascript">{`function OptanonWrapper() {}`}</script>

              <body className={highContrast ? 'wcag2aa' : ''} />
            </Helmet>
          )}
        </AppStateContext.Consumer>

        <LoginHeader />
        <LoginForm mode="onlyPatientLoginForm" />
        <Footer />
      </>
    );
  }
}
