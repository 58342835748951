import React from 'react';
import './login-type-select.scss';

import { AppStateContext } from 'src/contexts/app-state-provider.context';

class ReactSelect extends React.Component {
  state = { open: false, value: null };
  toggleOpen = () => this.setState({ open: !this.state.open });
  selectValue = (value, label) => () => {
    this.setState({ value, label, open: false });
  };

  onValueChange = event => {
    this.setState({ value: event.target.value });
    this.props.onChange(event);
  };

  render() {
    return (
      <div>
        <LoginSelect onValueChange={this.onValueChange} {...this.props} value={this.state.value} />
      </div>
    );
  }
}

const LoginSelect = ({ value, options, onValueChange, name, id }) => (
  <AppStateContext.Consumer>
    {({ language }) => (
      <select
        className={'loginSelect'}
        id={name}
        name={name}
        value={value === null ? 'placeholder' : value}
        onChange={onValueChange}
        onBlur={onValueChange}
      >
        <option disabled="disabled" value="placeholder">
          {language === 'french' ? 'Choisissez-en une' : 'Choose one'}
        </option>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label[language]}
          </option>
        ))}
      </select>
    )}
  </AppStateContext.Consumer>
);

export default ReactSelect;

ReactSelect.defaultProps = {
  onChange: function () {
    // do nothing.
  },
};
